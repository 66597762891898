@import "~antd/dist/antd.css";

.App {
  text-align: center;
}

.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 16px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #108ee9;
}

.logo {
  height: 32px;
  /*background: #333;
  border-radius: 6px;*/
  margin: 16px;
}

.ant-layout-sider-dark {
  /* background: rgb(7, 71, 166); */
  background: #202225;
}

.ant-menu-dark {
  /* background: rgb(7, 71, 166); */
  background: #202225;
}

.ant-menu-item {
  font-size: 12px !important;
}

.ant-menu.ant-menu-sub.ant-menu-inline {
  background-color: #fafafa;
}


.ant-menu-dark>li.ant-menu-item>a:hover {
  font-weight: bold;
}

.ant-layout-sider-collapsed .anticon {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.65);
}

.ant-layout-sider-collapsed .nav-text {
  display: none;
}

.dropbox {
  height: 180px;
  line-height: 1.5;
}

.editable-cell {
  position: relative;
}

.editable-cell-icon,
.editable-cell-icon-check {
  cursor: pointer;
}

.editable-cell-icon {
  line-height: 14px;
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -7px;
  display: none;
}

td:hover .editable-cell-icon {
  display: inline-block;
}

.editable-cell-icon:hover,
.editable-cell-icon-check:hover {
  color: #108ee9;
}

/*upgrade to v4 adjustments*/
.ant-legacy-form-item {
  margin-bottom: 0 !important;
}

div.ant-legacy-form-item {
  /* forcing vertical default layout */
  flex-direction: column;
}

.ant-legacy-form-vertical div.ant-legacy-form-item {
  /* forcing vertical default layout */
  flex-direction: unset;
}

.ant-form-item {
  margin-bottom: 0 !important;
  padding-bottom: 8px;
}


div.ant-legacy-form-item-label {
  /* forcing vertical default layout */
  text-align: left;
  flex-basis: min-content;

}

/* RTL specific adjustments */
.ant-row-rtl div.ant-legacy-form-item-label {
  /* forcing vertical default layout */
  text-align: right;
}

.ant-row-rtl .ant-legacy-form-item-required::before {
  margin-left: 4px;
}

.ant-dropdown-rtl .ant-dropdown-menu-title-content>span>.anticon {
  margin-left: 10px;
  margin-right: unset;
}

.noFlex {
  display: unset !important;
}

.flexGrow {
  flex-grow: 1;
}

.lateralMenu .ant-layout-sider-children {
  display: flex;
}

.lateralMenuCollapsed .ant-layout-sider-children {
  display: flex;
  flex-direction: column;
}

.ant-menu-vertical>.ant-menu-item {
  text-overflow: unset;
}

.ant-input {
  border-radius: 4px !important;
}

.ant-btn {
  border-radius: 4px;
}

.ant-tag {
  border-radius: 4px !important;
}

.ant-avatar-square {
  border-radius: 4px;
}

.ant-modal-content {
  border-radius: 4px;
}

.ant-menu {
  color: rgba(0, 0, 0, 0.65);
}

.ant-typography {
  word-break: unset !important;
}

.ant-menu-item {
  color: rgba(0, 0, 0, 0.65);
}

.ant-menu-item-selected .anticon {
  color: #1890ff;
}

.ant-tabs {
  overflow: unset;
}

.ant-badge {
  margin-right: 5px;
}

.ant-menu .ant-menu-selected::after,
.ant-menu .ant-menu-item-selected::after {
  -webkit-transform: scaleY(1);
  -ms-transform: scaleY(1);
  transform: scaleY(1);
  opacity: 1;
  -webkit-transition: opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}


.ant-menu-item a {
  color: rgba(0, 0, 0, 0.65) !important;
}

.ant-menu-item .anticon {
  margin-right: 10px;
}

.ant-layout-sider-children .ant-menu.ant-menu-inline-collapsed {
  width: 80px !important;
}

.ant-tabs-dropdown-menu-item .anticon {
  margin-right: 10px;
}

.ant-dropdown-menu-title-content>.anticon {
  margin-right: 10px;
}

.ant-dropdown-menu-title-content>span>.anticon {
  margin-right: 10px;
}

.ant-menu-horizontal .ant-menu-submenu-title .anticon {
  margin-right: 10px;
}

.ant-table-wrapper {
  overflow: auto;
}

/* DEFAULT PAGE STYLES */
.ant-page-header-heading {
  flex-wrap: wrap;
}

.ant-page-header-heading-left {
  overflow: unset;
}

.ant-page-header-heading-extra:not(.ant-page-header-rtl) {
  white-space: unset;
  margin-left: auto;
}

.ant-page-header-rtl .ant-page-header-heading-extra {
  white-space: unset;
  margin-right: auto;
}

.ant-descriptions-item-content {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.defaultPageExtra {
  display: flex;
  flex-wrap: wrap;
}

.defaultPageButtons {
  text-wrap: nowrap;
}

.defaultPageLinkButtons {
  text-wrap: nowrap;
}

/* DEFAULT PAGE STYLES END */
.ant-tabs-dropdown-menu {
  max-height: 500px;
}

div.ant-popover-inner-content {
  width: 100%;
}

.custom-filter-dropdown {
  padding: 8px;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
}

.custom-filter-dropdown input {
  width: 130px;
  margin-right: 8px;
}

.highlight {
  color: #f50;
}

.line-label {
  font-size: 10px;
  font-family: "Lucida Grande", Lucida, Verdana, sans-serif;
}

.bound {
  font-size: 10px;
  font-family: "Lucida Grande", Lucida, Verdana, sans-serif;
  fill: #999;
}

div.d3tooltip {
  position: absolute;
  padding: 8px;
  text-align: left;
  background: rgba(0, 0, 0, 0.87);
  color: #fff;
  border: 0px;
  border-radius: 8px;
  pointer-events: none;
}

circle.drop {
  cursor: pointer;
}

.powered-by-logo {
  display: none !important;
}



.ant-page-header-heading-title {
  font-weight: 500 !important;
  color: rgba(0, 0, 0, 0.7) !important;
}

h4.ant-typography,
.ant-typography h4 {
  font-weight: 500 !important;
  font-size: 18px !important;
  color: rgba(0, 0, 0, 0.7) !important;
}

h3.ant-typography,
.ant-typography h3 {
  font-weight: 400 !important;
}

h2.ant-typography,
.ant-typography h2 {
  font-weight: 400 !important;
}

h1.ant-typography,
.ant-typography h1 {
  font-weight: 400 !important;
}

/* scrollbar similar to macos */
.win ::-webkit-scrollbar {
  background-color: #fff !important;
  width: 16px !important;
}

.win ::-webkit-scrollbar-track {
  background-color: #fff !important;
}

.win ::-webkit-scrollbar-track:hover {
  background-color: #f4f4f4 !important;
}

.win ::-webkit-scrollbar-thumb {
  background-color: #babac0 !important;
  border-radius: 16px !important;
  border: 5px solid #fff !important;
}

.win ::-webkit-scrollbar-thumb:hover {
  background-color: #a0a0a5 !important;
  border: 4px solid #f4f4f4 !important;
}

.win ::-webkit-scrollbar-button {
  display: none !important;
}

/* show only when hover */
.win .ant-table-body {
  overflow-y: hidden !important;
  overflow-x: hidden !important;
}

.win .ant-table-body:hover {
  overflow-y: auto !important;
  overflow-x: auto !important;
}

.win .ant-menu::-webkit-scrollbar {
  display: none !important;
}

.hideStep .ant-input-number-handler-wrap {
  visibility: hidden !important;
}


/* Hide scrollbar for WebKit browsers (Chrome, Safari) */
.expandedLateralMenu::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for other browsers (Firefox, IE) */
.expandedLateralMenu {
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
}

div.disabled {
  /* for "disabled" effect */
  opacity: 0.7;
  cursor: not-allowed; /* Change cursor to indicate it's disabled */
}
div.disabled * {
  pointer-events: none; /* Disable interaction for child elements */
}

.collapseButton {
  border-radius: 50%;
  background: white;
  box-shadow: rgba(9, 30, 66, 0.08) 0px 0px 0px 1px, rgba(9, 30, 66, 0.08) 0px 2px 4px 1px;
  font-size: 14px;
  height: 22px;
  width: 22px;
}

.collapseButton:hover {
  background-color: rgb(38, 132, 255);
  color: white;
}

.calcSheetStyle .luckysheet-share-logo {
  display: none;
}

.calcSheetStyle .luckysheet_info_detail_back {
  display: none;
}

.calcSheetStyle .luckysheet-grid-window {
  z-index: 1;
}

.CodeMirror {
  border: 1px solid #eee;
  height: auto !important;
}

.CodeMirror-scroll {
  overflow-y: hidden;
  overflow-x: auto;
}

.smallMenu>li {
  padding: 2px 12px 2px 12px;
}

/* self service */
.ssHeader .ant-menu-horizontal {
  border: none;
}

.paginationLeft ul.ant-table-pagination.ant-pagination {
  float: left;
}

.has-error .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector {
  background-color: #fff;
  border-color: #ff4d4f !important;
}

.sisos-search-input-group .ant-input-group > .ant-input:first-child,
.sisos-search-input-group .ant-input-group-addon:first-child {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  font-size: 14px !important;
  height: 40px !important;
  line-height: 40px !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  color:#707070 !important;
}
.react-flow__attribution {
  display: none;
}