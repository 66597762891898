/* Cal-HeatMap CSS */

.cal-heatmap-container {
	display: block;
}

.graph
{
	font-family: "Lucida Grande", Lucida, Verdana, sans-serif;
}

.graph-label
{
	fill: #999;
	font-size: 10px
}

.graph, .graph-legend rect {
	shape-rendering: crispedges
}

.graph-rect
{
	fill: #ededed
}

.graph-subdomain-group rect:hover
{
	stroke: #000;
	stroke-width: 1px
}

.subdomain-text {
	font-size: 8px;
	fill: #999;
	pointer-events: none
}

.hover_cursor:hover {
	cursor: pointer
}

.qi {
	background-color: #999;
	fill: #999
}

/*
Remove comment to apply this style to date with value equal to 0
.q0
{
	background-color: #fff;
	fill: #fff;
	stroke: #ededed
}
*/

.q1
{
	background-color: #dae289;
	fill: #dae289
}

.q2
{
	background-color: #cedb9c;
	fill: #9cc069
}

.q3
{
	background-color: #b5cf6b;
	fill: #669d45
}

.q4
{
	background-color: #637939;
	fill: #637939
}

.q5
{
	background-color: #3b6427;
	fill: #3b6427
}

rect.highlight
{
	stroke:#444;
	stroke-width:1
}

text.highlight
{
	fill: #444
}

rect.now
{
	stroke: red
}

text.now
{
	fill: red;
	font-weight: 800
}

.domain-background {
	fill: none;
	shape-rendering: crispedges
}

.ch-tooltip {
	padding: 10px;
	background: #222;
	color: #bbb;
	font-size: 12px;
	line-height: 1.4;
	width: 140px;
	position: absolute;
	z-index: 99999;
	text-align: center;
	border-radius: 2px;
	box-shadow: 2px 2px 2px rgba(0,0,0,0.2);
	display: none;
	box-sizing: border-box;
}

.ch-tooltip::after{
	position: absolute;
	width: 0;
	height: 0;
	border-color: transparent;
	border-style: solid;
	content: "";
	padding: 0;
	display: "block";
	bottom: -6px;
	left: 50%;
	margin-left: -6px;
    border-width: 6px 6px 0;
    border-top-color: #222;
}
